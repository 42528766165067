@font-face {
  font-family: 'caesarDressingRegular';
  src: url('/public/assets/games/dede/fonts/CaesarDressing-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'brlnsr';
  src: url('/public/assets/games/dede/fonts/brlnsr.ttf') format('truetype');
}
@font-face {
  font-family: 'ManchoBold';
  src: url('/public/assets/games/dede/fonts/Mancho-Bold.OTF') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'kanitSemiBold';
  src: url('/public/assets/games/dede/fonts/Kanit-SemiBold.ttf') format('truetype');
}
@font-face {
  font-family: 'kanitRegular';
  src: url('/public/assets/games/dede/fonts/Kanit-Regular.ttf') format('truetype');
}
/*@font-face {*/
/*  font-family: 'kanitSemiBold';*/
/*  src: url('https://slots.sandbox.accamax.com/assets/games/dede/fonts/Kanit-SemiBold.ttf') format('truetype');*/
/*}*/

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  background-color: #2e3532;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.ant-modal-content {
  border: 5px solid #ffe771 !important;
}
/* .ant-modal {
  margin-top: -57px !important;
} */

.ant-modal-wrap {
  overflow: hidden;
}

.ant-tabs-tab {
  margin: 0px 12px 0px 0px !important;
}

* {
  font-family: 'ManchoBold', sans-serif !important;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  touch-action: none;
  -webkit-touch-callout: none;
}
